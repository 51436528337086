import React from 'react';
import './StatisticsAdmin.scss';

const StatisticsAdmin = () => {
  return (
    <div className="statisticts_admin">
      <br />
      65756756755
      <br />
    </div>
  );
};

export default StatisticsAdmin;
