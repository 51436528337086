import React from "react";
import RouterSwitch from "./configs/RouterSwitch";
import { BrowserRouter as Router } from "react-router-dom";
import { MenuMain, SideMenu, Footer } from "./components";
import { useClientViewData } from "./tools";
import { useState, useEffect } from "react";
import useScreenSize from "./hooks/useScreenSize";
import Modals from "./Modals";
import "./App.scss";
import "./common_constants/styles/textStyle.scss";

const App = () => {
  useClientViewData();
  useScreenSize();
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 3500);
  }, []);

  return (
    <div className="App font-style">
      <Router>
        {isLoad === true ? (
          <div className="Preloader">
            <div class="lava-lamp">
              <div class="bubble"></div>
              <div class="bubble1"></div>
              <div class="bubble2"></div>
              <div class="bubble3"></div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="App-side-menu-and-content">
          <SideMenu />
          <div className="App-menu-main-and-content">
            <MenuMain />
            <RouterSwitch />
          </div>
        </div>
        <Footer />
        <Modals />
      </Router>
    </div>
  );
};

export default App;
