import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ROUTES } from "../../common_constants/routes";
import { AUTH } from "../../common_constants/modals";
import { NAME_SELECT } from "../../common_constants/business";
import { setModal } from "../../store/commonReducer";
import {
  logo_menu_component,
  icon_user_black,
  icon_heart_empty_black,
  shopping_bag_baskets_main,
  goods,
  orders,
} from "../../images";

import { FlexBox } from "../";
import { PrimaryButton } from "../";

import "./MenuMain.scss";

const MenuMain = () => {
  const { isDesktopScreen } = useSelector((state) => state.screenSize.deviceType);
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.common.basket) ?? [];
  const userAuth = useSelector((state) => state.common.userAuth);
  const { isAdmin, isClientOrAbove, isGuest, isNotAdmin, isClientOrLower } = useSelector(
    (state) => state.common.accessRoles
  );
  const [showBtn, setShowBtn] = useState(false);

  const onBtnClickAuth = () => {
    dispatch(setModal({ name: AUTH }));
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 600) {
      setShowBtn(false);
    } else if (width < 600) {
      setShowBtn(true);
    }
  };

  const logout = () => {
    window.localStorage.removeItem("accessToken");
    window.location.reload();
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const LogoCompany = () => (
    <Link className="company-logo" to={ROUTES.HOME_DASHBOARD}>
      <img src={logo_menu_component} alt="Company Logo" />
    </Link>
  );

  const MenuAdmin = () => (
    <div className="menu-admin">
      <Link className="menu-admin-btn" to={ROUTES.ORDER_ADMIN}>
        {showBtn ? (
          <button
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(180deg, #467d33 0%, #20786d 100%)",
              borderRadius: "8px",
              border: "none",
              outline: "none",
            }}
          >
            <img src={orders} alt="btn-like" style={{ width: "30px", height: "30px" }} />
          </button>
        ) : (
          <PrimaryButton children="Замовлення" color="gradient_main" />
        )}
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Link className="menu-admin-btn" to={ROUTES.PRODUCTS_ADMIN}>
        {showBtn ? (
          <button
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(180deg, #467d33 0%, #20786d 100%)",
              borderRadius: "8px",
              border: "none",
              outline: "none",
            }}
          >
            <img src={goods} alt="btn-like" style={{ width: "30px", height: "30px" }} />
          </button>
        ) : (
          <PrimaryButton children="Товари" color="gradient_main" />
        )}
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  );

  const BtnLike = () => (
    <FlexBox>
      <div className="btn_auth_logo">
        <img src={icon_heart_empty_black} alt="btn-like" />
      </div>
    </FlexBox>
  );

  return (
    <header className="menu-main">
      {isDesktopScreen && <LogoCompany />}
      <FlexBox mt={0} className="group-links">
        {isDesktopScreen && (
          <div className="menu-part">
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="links">
              <Link className="links-item" to={ROUTES.HOME_DASHBOARD} children="Головна" />
              &nbsp;&nbsp;
              <Link className="links-item" to={ROUTES.SHOP} children="Магазин" />
            </div>
          </div>
        )}

        {!isDesktopScreen && <LogoCompany />}

        {isAdmin && <MenuAdmin />}

        <div className="menu-part">
          <div className="btn-auth text-link">
            <FlexBox>
              {isClientOrAbove ? (
                <Link to={{ pathname: ROUTES.PERSONAL_OFFICE, state: { selectParam: NAME_SELECT.ACCOUNT } }}>
                  <div className="btn_auth_logo">
                    <img src={icon_user_black} alt="btn-login" />
                  </div>
                </Link>
              ) : (
                <button
                  name="login in and login out"
                  className="btn_auth_logo"
                  style={{ padding: 0 }}
                  onClick={userAuth ? logout : onBtnClickAuth}
                >
                  {!isClientOrAbove && <img src={icon_user_black} alt="btn-login" />}
                </button>
              )}
            </FlexBox>
          </div>
          &nbsp;&nbsp;
          {isClientOrLower &&
            (isGuest ? (
              <button className="btn_auth text-link" onClick={onBtnClickAuth}>
                <BtnLike />
              </button>
            ) : (
              <Link
                className="btn_auth text-link"
                to={{ pathname: ROUTES.PERSONAL_OFFICE, state: { selectParam: NAME_SELECT.WISHLIST } }}
              >
                <BtnLike />
              </Link>
            ))}
          &nbsp;&nbsp;
          {isNotAdmin && (
            <Link
              className={clsx("btn_auth basket-icon", { isNotDesktopScreen: !isDesktopScreen })}
              to={{ pathname: ROUTES.PERSONAL_OFFICE, state: { selectParam: NAME_SELECT.BASKETLIST } }}
            >
              <img src={shopping_bag_baskets_main} alt="btn-basket" />
              {basket?.length > 0 && <span className="basket-count">{basket.length}</span>}
            </Link>
          )}
        </div>
      </FlexBox>
    </header>
  );
};

export default MenuMain;
