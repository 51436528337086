const ProductDesc2 = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2013_2189)">
        <path
          d="M19.4062 20.5312L15 16.125L10.5938 20.5312C13.2188 22.5938 16.875 22.5938 19.4062 20.5312ZM14.0625 15.1875L9.375 10.5C7.21875 13.2188 7.3125 16.9687 9.5625 19.5938L13.9688 15.1875H14.0625ZM25.2187 5.90625L21.4687 9.65625C24 12.8438 23.9062 17.4375 21.2812 20.5312L25.2187 24.4688V5.90625ZM20.625 10.5L15.9375 15.1875L20.3438 19.5938C22.5938 17.0625 22.6875 13.2188 20.5312 10.5H20.625ZM9.75 21.375L6 25.125H24L20.25 21.375C17.25 23.9062 12.75 23.9062 9.75 21.375ZM3 26.9062V2.90625H27V26.9062H3ZM4.78125 24.4688L8.71875 20.5312C5.90625 17.4375 5.8125 12.8438 8.53125 9.65625L4.78125 5.90625V24.4688ZM15 14.3438L19.6875 9.65625C16.9687 7.3125 13.0312 7.3125 10.3125 9.65625L15 14.3438ZM20.5312 8.8125L24.4688 4.875H5.4375L9.375 8.8125C12.5625 5.90625 17.4375 5.90625 20.5312 8.8125Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2013_2189">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProductDesc2;
