import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Questions = () => {
  return (
    <div className="InfoPage">
      <div className="InfoPage__content">
        <div style={{ textTransform: "uppercase", display: "flex", gap: "5px", alignItems: "center" }}>
          <Link to="/" className="shopLink">
            Головна
          </Link>
          &gt;&gt; <div style={{ fontWeight: 600, color: "#000" }}>Часті запитання</div>
        </div>
        <h3>Часті запитання</h3>
        <h4>Оформлення замовлення</h4>
        <p className="qest_p">
          По-перше, для отримання послуг Покупець самостійно формує замовлення у затвердженій формі і відповідно до
          правил надсилає його працівникам сайту. Продавець не несе відповідальності за достовірність даних, зазначених
          при реєстрації Покупцем.
        </p>
        <h4>Зробіть аванс</h4>
        <p className="qest_p">
          Покупець повинен внести аванс. Крім того, у деяких випадках у вас буде доступ до накладеної доставки. Це все
          обговорюється з Вашим персональним менеджером.
        </p>
        <h4>Чекаємо на замовлення</h4>
        <p className="qest_p">
          Процес доставки швидкий. Якщо товар на складі, Ви отримаєте його протягом 1-3 днів. Товар під замовлення
          використовує авіаперевезення, на що в середньому йде 7 – 17 робочих днів. Але іноді у випадку перевірок на
          митниці, святкових днів можуть виникнути затримки. Оформлення і виготовлення товару здійснюється відразу після
          внесення авансу.
        </p>
        <h4>Відправка кур’єрською службою по країні</h4>
        <p className="qest_p">
          Продавець не несе відповідальності за дії пошти, кур’єрських служб та транспортних компаній, а також не може
          вплинути на швидкість доставки.
        </p>
        <h4>Як скоро я отримаю своє замовлення?</h4>
        <p className="qest_p">
          З моменту оплати, у той же день, ми починаємо викуповувати замовлені вами речі, якщо їх немає на складі. Потім
          ми чекаємо, поки речі від наших співробітників з -за кордону (США, Азія, Європа) прибудуть на наш склад.
          Орієнтовний термін доставки 7-17 робочих днів. Термін доставки товару залежить від багатьох факторів, а саме:
          Виготовлення товару, святкові дні, затримки на митниці. Ми, у свою чергу, зобов’язуємось нагадати працівникам
          про найшвидшу відправку товару на наш склад. Потім ми перевіряємо товари, які є на складі, складаємо фотозвіт
          (на вимогу), пакуємо їх і відправляємо по Україні.
        </p>
        <h4>Які причини затримки доставки?</h4>
        <p className="qest_p">
          Як правило, затримки бувають через державні свята, через перевірки на митниці, у такому разі затримуються всі
          відправки всіх відправників. Якщо Ваш товар надійшов на наш склад, ми його миттєво відправляємо кур’єрською
          службою. Ми не затримуємо товар, який прийшов на склад.
        </p>
      </div>
    </div>
  );
};

export default Questions;
