import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { Card } from "../../components";
import { PRODUCT_SUBCATEGORIES } from "../../common_constants/business";
import { home_dashboard_poster } from "../../images";
import "./HomeDashboard.scss";

const HomeDashboard = () => {
  const [newProds, setNewProds] = useState([]);
  const [topProds, setTopProds] = useState([]);
  const products = useSelector((state) => state.common.products) ?? [];

  useEffect(() => {
    let news = [];
    let tops = [];

    for (let i = 0; i < products.length; i++) {
      if (products[i].rating === "2") {
        if (news.length < 4) {
          news.push(products[i]);
        }
      }
      if (products[i].rating === "3") {
        if (tops.length < 8) {
          tops.push(products[i]);
        }
      }
    }
    setNewProds(news);
    setTopProds(tops);
  }, [products]);

  return (
    <div className="home-dashboard-page">
      <div className="billboard HomeBg">
        <>FASH7UA</>
      </div>
      <Card className="shadow" mt={75} title="ПОПУЛЯРНІ КАТЕГОРІЇ" listCategories={PRODUCT_SUBCATEGORIES} />
      <Card className="shadow" mt={75} title="ГАРЯЧІ НОВИНКИ" listProducts={newProds} status="new" />
      <Card className="shadow" mt={75} title="ЛІДЕРИ ПРОДАЖІВ" listProducts={topProds} status="rating" />
    </div>
  );
};

export default HomeDashboard;
