import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  addFavoriteProduct,
  addBasket,
  removeFavoriteProduct,
  deleteProduct,
  removeBasket,
  setModal,
} from "../../store/commonReducer";

import { AUTH } from "../../common_constants/modals";
import { ROUTES } from "../../common_constants/routes";
import { ACTION, NAME_SELECT } from "../../common_constants/business";

import { request } from "../../tools";
import { icon_heart_empty_red, icon_heart_empty_gray } from "../../images";
import { Spin, PreviewImage, PrimaryButton, Typography } from "../";

import "./Product.scss";
import EditModal from "../EditModal/EditModal";

const Product = ({ item, selectedCard, status }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isGuest, isNotAdmin, isClientOrLower } = useSelector((state) => state.common.accessRoles);

  const [loadingPutWishList, setLoadingPutWishList] = useState(false);
  const [loading, setLoading] = useState(false);

  const isPagePersonalOffice = pathname === ROUTES.PERSONAL_OFFICE && selectedCard === NAME_SELECT.BASKETLIST;
  const isPageProductAdmin = pathname === ROUTES.PRODUCTS_ADMIN;
  const isLikeProduct = item.isFavorite ? icon_heart_empty_red : icon_heart_empty_gray;

  const textAddBtnDynamic = isPagePersonalOffice ? "Додати ще один" : "Додати в кошик";

  const onPutInBasket = () => {
    setLoading(true);
    dispatch(addBasket(item._id));
    setLoading(false);
  };

  const onDelInBasket = () => {
    setLoading(true);
    dispatch(removeBasket(item._id));
    setLoading(false);
  };

  const onBtnClickRemoveProduct = async () => {
    setLoading(true);

    await request.delete("/products/", item._id, (res) => {
      dispatch(deleteProduct(res.data));
    });

    setLoading(false);
  };

  const onBtnClickAuth = () => {
    dispatch(setModal({ name: AUTH }));
  };

  const onPutWishList = () => {
    setLoadingPutWishList(true);
    handleToFavorites(item._id, ACTION[item.isFavorite ? "REMOVE" : "ADD"]);
    setLoadingPutWishList(false);
  };

  async function handleToFavorites(productId, action) {
    const data = { productId, action: action };

    await request.patch(
      "/clients/changeFavorites",
      data,
      () => {
        if (action === ACTION.ADD) dispatch(addFavoriteProduct(productId));
        else if (action === ACTION.REMOVE) dispatch(removeFavoriteProduct(productId));
      },
      (error) => {
        console.error("Error adding to favorites:", error);
      }
    );
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const productEditing = (
    <Fragment>
      <PrimaryButton mt={8} className="danger" children="Видалити" onClick={onBtnClickRemoveProduct} />
      <PrimaryButton
        mt={8}
        className="put"
        children="Редагувати"
        onClick={() => {
          setIsModalOpen(true);
          console.log(item);
        }}
      />
    </Fragment>
  );

  const removeItemsBasket = (
    <PrimaryButton className="danger" mt={8} children="Видалити один" onClick={onDelInBasket} />
  );
  return (
    <>
      {isPageProductAdmin && isModalOpen && <EditModal closeModal={closeModal} item={item}></EditModal>}
      <div className="product">
        <Spin spinning={loading}>
          <div>
            {isClientOrLower && !isPageProductAdmin && (
              <Spin spinning={loadingPutWishList}>
                <button className="btn-first product-like-icon" onClick={isGuest ? onBtnClickAuth : onPutWishList}>
                  <img src={isLikeProduct} alt="btn menu" />
                </button>
              </Spin>
            )}
            {status === "new" && (
              <div className="NewProductLabel">
                <p>Новинка</p>
              </div>
            )}
            {status === "rating" && (
              <div className="RateProductLabel">
                <p>ТОП</p>
              </div>
            )}
            <Link className="menu-admin-btn" to={`${ROUTES.CARD_PRODUCT}/${item._id}`}>
              <PreviewImage fileID={item?.f?.[0]?.files?.[0]} />
            </Link>
          </div>
          <div>
            <Typography mt={10} color="gray100" sz={10} fw={400} children={item.n} />
            <Typography mt={10} mb={10} color="gray100" sz={10} fw={600} children={`${item.p} ₴`} />
            {isNotAdmin && !isPageProductAdmin && (
              <PrimaryButton children={textAddBtnDynamic} onClick={onPutInBasket} />
            )}
            {isPagePersonalOffice && removeItemsBasket}
            {isPageProductAdmin && productEditing}
          </div>
        </Spin>
      </div>
    </>
  );
};

export default Product;
