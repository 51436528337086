import React, { Fragment } from "react";
import "./Spin.scss";

const Spin = ({ children, spinning, tip }) => {
  return (
    <div className="spin-wrapper">
      <div className="children-container">{children}</div>

      {spinning && (
        <>
          <div class="spinner"></div>
          {tip && <div className="loading-text">{tip}</div>}
        </>
      )}
    </div>
  );
};

export default Spin;
