import "./EditModal.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Box, Typography } from "../../components";
import { request } from "../../tools";
import { PRODUCT_CATEGORIES, SIZE_OPTIONS } from "../../common_constants/business";
import { updateProduct } from "../../store/commonReducer";

const EditModal = ({ closeModal, item }) => {
  const dispatch = useDispatch();
  const clickToClose = () => {
    closeModal();
  };
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    productName: item.n,
    description: item.d,
    price: item.p,
    category: item.c[0],
    subcategory: item.c[1],
    status: item.status,
    rating: item.rating,
    colors: item.f,
    sizes: item.s,
  });
  const [Label] = [
    ({ children, mt }) => <Typography children={children} mt={mt ?? 0} sz={30} fw={700} />,
    ({ children }) => <Typography children={children} mb={4} fs={12} fw={600} />,
  ];

  const handleChange = (e, index) => {
    const { name, value, files } = e.target;
    if (name === "colorName") {
      const updatedColors = [...formData.colors];
      updatedColors[index] = { ...updatedColors[index] };
      setFormData({ ...formData, colors: updatedColors });
    } else if (name === "colorImage") {
      const updatedColors = [...formData.colors];
      const images = updatedColors[index].files.concat([...files]);
      updatedColors[index] = { ...updatedColors[index], images };
      console.log(updatedColors[index].images);
      setFormData({ ...formData, colors: updatedColors });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCategoryChange = (e) => {
    setFormData({ ...formData, category: e.target.value, subcategory: "" });
  };
  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: e.target.value });
  };
  const handleRatingChange = (e) => {
    setFormData({ ...formData, rating: e.target.value });
  };

  const handleSubcategoryChange = (e) => {
    setFormData({ ...formData, subcategory: e.target.value });
  };

  const toggleSize = (size) => {
    setFormData((prevData) => {
      const newSizes = prevData.sizes.includes(size)
        ? prevData.sizes.filter((s) => s !== size)
        : [...prevData.sizes, size];
      return { ...prevData, sizes: newSizes };
    });
  };
  const selectedCategory = PRODUCT_CATEGORIES.find((cat) => cat.value === parseInt(formData.category));

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();

    if (formData.sizes.length === 0) {
      setFormError("Будь ласка, оберіть хоча б один розмір.");
      return;
    }

    console.log(formData.sizes);
    const body = new FormData();
    body.append("productID", item._id);
    body.append("productName", formData.productName);
    body.append("description", formData.description);
    body.append("price", formData.price);
    body.append("category", formData.category);
    body.append("subcategory", formData.subcategory);
    body.append("status", formData.status);
    body.append("rating", formData.rating);
    body.append("sizes", JSON.stringify(formData.sizes));
    body.append("colorsInfo", JSON.stringify(formData.colors));

    request.put("/products/updProduct", body, (res) => {
      console.log("Товар успішно додано", res);
      if (res?.data) dispatch(updateProduct(res.data));
      dispatch(clickToClose);
    });
  };

  return (
    <>
      <div className="editOverlay" onClick={clickToClose}></div>
      <div className="editModal">
        <div className="editModalHeader">
          <h4>Редагування товару</h4>
          <span className="closeEditModal" onClick={clickToClose}>
            &times;
          </span>
        </div>
        <form onSubmit={handleSubmit} className="product-form-add">
          <label className="form-label">
            <Label> Назва товару:</Label>
            <input
              type="text"
              name="productName"
              value={formData.productName}
              onChange={(e) => handleChange(e)}
              className="form-input"
              required
            />
          </label>
          <label className="form-label">
            <Label> Опис:</Label>
            <textarea
              name="description"
              value={formData.description}
              onChange={(e) => handleChange(e)}
              className="form-input textarea"
              required
            />
          </label>
          <Box className="input-group">
            <Label>Ціна</Label>
            <input
              placeholder="Введіть ціну"
              aria-label="price"
              id="price"
              type="number"
              name="price"
              value={formData.price}
              onChange={(e) => handleChange(e)}
            />
          </Box>
          <label className="form-label">
            <Label>Категорія:</Label>
            <select
              name="category"
              value={formData.category}
              onChange={handleCategoryChange}
              className="form-input"
              required
            >
              <option value="">Оберіть категорію</option>
              {PRODUCT_CATEGORIES.map((cat) => (
                <option key={cat.value} value={cat.value}>
                  {cat.label}
                </option>
              ))}
            </select>
          </label>
          {selectedCategory && (
            <label className="form-label">
              <Label>Підкатегорія:</Label>
              <select
                name="subcategory"
                value={formData.subcategory}
                onChange={handleSubcategoryChange}
                className="form-input"
                required
              >
                <option value="">Оберіть підкатегорію</option>
                {selectedCategory.subcategories.map((subcat) => (
                  <option key={subcat.value} value={subcat.value}>
                    {subcat.label}
                  </option>
                ))}
              </select>
            </label>
          )}
          <label className="form-label">
            <Label>Статус товару:</Label>
            <select name="status" value={formData.status} onChange={handleStatusChange} className="form-input" required>
              <option value="">Оберіть статус</option>
              <option value="1">У наявності</option>
              <option value="2">Під замовлення</option>
            </select>
          </label>
          <label className="form-label">
            <Label>Рейтинг товару:</Label>
            <select name="rating" value={formData.rating} onChange={handleRatingChange} className="form-input" required>
              <option value="">Оберіть рейтинг</option>
              <option value="1">Звичайний товар</option>
              <option value="2">Гаряча Новинка</option>
              <option value="3">Лідер продажів</option>
            </select>
          </label>
          <label className="form-label">
            <Label>Розміри</Label>
            <div className="size-selector">
              {SIZE_OPTIONS.map((size) => (
                <button
                  type="button"
                  key={size}
                  className={clsx("size-button", { selected: formData.sizes.includes(size) })}
                  onClick={() => toggleSize(size)}
                >
                  {size}
                </button>
              ))}
            </div>
            {formError && <p className="form-error">{formError}</p>}
          </label>
          <button type="submit" className="form-button">
            Завершити редагування товару
          </button>
        </form>
      </div>
    </>
  );
};

export default EditModal;
