import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";
import { icons8_like_96, icons8_bag_96, icons8_house_90, Icon_menu_sidemenu } from "../../images";
import { ROUTES } from "../../common_constants/routes";
import { PRODUCT_CATEGORIES } from "../../common_constants/business";
import "./SideMenu.scss";

const SideMenu = () => {
  const sideMenuSecond = useRef(null);
  const [sideSecondData, setSideSecondData] = useState([]);
  const [link, setLink] = useState("");
  const [isHoveredFirst, setIsHoveredFirst] = useState(false);
  const [isHoveredSecond, setIsHoveredSecond] = useState(false);

  const handleMouseEnter = (item, link) => {
    setSideSecondData(item.subcategories);
    setLink(link);
    setIsHoveredSecond(true);
  };

  const handleMouseLeave = () => {
    if (isHoveredFirst && isHoveredSecond) {
      setIsHoveredFirst(false);
      setIsHoveredSecond(false);
    } else if (isHoveredFirst && !isHoveredSecond) {
      setIsHoveredFirst(false);
    }
  };

  return (
    <>
      <div
        className={`sideMenu ${isHoveredFirst ? "hovered" : ""}`}
        onMouseEnter={() => setIsHoveredFirst(true)}
        onMouseLeave={() => handleMouseLeave()}
      >
        <Link to={ROUTES.HOME_DASHBOARD} className="sideBtn">
          <button className="sideBtnFirst">
            <img src={Icon_menu_sidemenu} alt="homePath" className="Menu"></img>
            <p className="btnSideTextMenu">Меню</p>
          </button>
        </Link>
        <Link to={ROUTES.SHOP} className="sideBtn">
          <button className="sideBtnFirst">
            <img src={icons8_bag_96} alt="homePath" className="Categories"></img>
            <p className="btnSideText">Магазин</p>
          </button>
        </Link>
        {PRODUCT_CATEGORIES.map((item, index) => (
          <button
            className="sideBtnCat"
            key={index}
            onMouseEnter={() => handleMouseEnter(item, item.value)}
            onMouseLeave={() => {
              setIsHoveredSecond(false);
            }}
          >
            <Link to={`shop?category=${item.value}`} className="sideCat">
              <img src={item.img} alt="homePath" className={`Categories ${item.value}`}></img>
            </Link>
            <div className="btnSideTextBlock">
              <p className="btnSideTextCategory">{item.label}</p>
            </div>
          </button>
        ))}
        <Link to={ROUTES.LEADERS} className="sideBtn">
          <button className="sideBtnFirst">
            <img src={icons8_like_96} alt="homePath" className="Categories"></img>
            <p className="btnSideText">Гарячі новинки</p>
          </button>
        </Link>
        <Link to={ROUTES.AVAILABILITY} className="sideBtn">
          <button className="sideBtnFirst">
            <img src={icons8_house_90} alt="homePath" className="Categories"></img>
            <p className="btnSideText">У наявності</p>
          </button>
        </Link>
      </div>
      <div
        className={`sideMenuSecond ${isHoveredSecond ? "hovered" : ""}`}
        ref={sideMenuSecond}
        onMouseEnter={() => {
          setIsHoveredFirst(true);
          setIsHoveredSecond(true);
        }}
        onMouseLeave={handleMouseLeave}
      >
        {sideSecondData.length !== 0 &&
          sideSecondData.map((data, index) => (
            <div className="sideSecondColumn" key={index} onClick={() => handleMouseLeave()}>
              {data.titleTopGroup && <h4>{data.titleTopGroup}</h4>}
              <Link to={`shop?category=${link}&subcategory=${data.value}`}>
                <button className="sideSecondBtn">{data.label}</button>
              </Link>
            </div>
          ))}
      </div>
    </>
  );
};

export default SideMenu;
