import React from "react";
import { useSelector } from "react-redux";
import { ROUTES } from "../common_constants/routes";
import { Route, Switch } from "react-router-dom";

import HomeDashboard from "../pages/HomeDashboard";
import Shop from "../pages/Shop";
import OrderAdmin from "../pages/OrderAdmin";
import ProductsAdmin from "../pages/ProductsAdmin";
import CardProduct from "../pages/CardProduct/CardProduct.jsx";
import StatisticsAdmin from "../pages/StatisticsAdmin";
import PersonalOffice from "../pages/PersonalOffice";
import Leaders from "../pages/Leaders/Leaders.jsx";
import Availability from "../pages/Availability/Availability.jsx";
import Deliver from "../components/Deliver.jsx";
import Paying from "../components/Paying.jsx";
import Questions from "../components/Qestions.jsx";

const RouterSwitch = () => {
  const { isAdmin } = useSelector((state) => state.common.accessRoles);

  return (
    <Switch>
      {isAdmin && <Route exact path={ROUTES.ORDER_ADMIN} component={OrderAdmin} />}
      {isAdmin && <Route exact path={ROUTES.PRODUCTS_ADMIN} component={ProductsAdmin} />}
      {isAdmin && <Route exact path={ROUTES.STATISTICS_ADMIN} component={StatisticsAdmin} />}

      <Route exact path={ROUTES.HOME_DASHBOARD} component={HomeDashboard} />
      <Route exact path={ROUTES.PERSONAL_OFFICE} component={PersonalOffice} />
      <Route exact path={ROUTES.SHOP} component={Shop} />
      <Route exact path={ROUTES.LEADERS} component={Leaders} />
      <Route exact path={ROUTES.AVAILABILITY} component={Availability} />
      <Route exact path={`${ROUTES.CARD_PRODUCT}/:productId`} component={CardProduct} />
      <Route exact path={`/deliver`} component={Deliver} />
      <Route exact path={`/payment`} component={Paying} />
      <Route exact path={`/qestions`} component={Questions} />
      <Route component={HomeDashboard} />
    </Switch>
  );
};

export default RouterSwitch;
