import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Deliver = () => {
  return (
    <div className="InfoPage">
      <div className="InfoPage__content">
        <div style={{ textTransform: "uppercase", display: "flex", gap: "5px", alignItems: "center" }}>
          <Link to="/" className="shopLink">
            Головна
          </Link>
          &gt;&gt; <div style={{ fontWeight: 600, color: "#000" }}>Доставка</div>
        </div>
        <h3>ДОСТАВКА</h3>
        <h5>Новою Поштою в будь-який куточок України 1-3 днів.</h5>
        <p>
          Терміни доставки залежать від Вашого регіону:
          <br />
          <br />
          Термін (роб. днів) Населений пункт
          <br />
          <br />
          1-3 днiв: Вінниця, Волинь, Дніпро, Житомир, Закарпаття, Запоріжжя, Івано-Франківськ, Київ, Ковель, Коломия,
          Кременчук, Львів, Миколаїв, Нововолинськ, Одеса, Полтава, Рівне, Суми, Тернопіль, Харків, Херсон,
          Хмельницький, Черкаси, Чернівці, Чернігів
          <br />5 - 8 днiв: Броди, Володимир-Волинський, Дрогобич, Камянське, Кривий Ріг, Кропивницький, Лубни,
          Мелітополь, Нікополь, Павлоград, Стрий, Червоноград <br />
          <br />
          Інші населені пункти: Вiд 7 до 11 днiв <br />
          Частковий викуп речей із замовлення неможливий. <br />
          Якщо замовлення не вдається вручити за вказаною адресою з першого разу, кур’єр спробує зробити це ще раз.{" "}
          <br />
          Якщо посилку не буде вручено з другої спроби, вона повертається до інтернет-магазину.
        </p>
      </div>
    </div>
  );
};

export default Deliver;
