import React, { useState, useEffect } from "react";
import { request } from "../../tools";
import { Spin } from "../";
import clsx from "clsx";
import { ERROR_IMAGE_URL } from "../../common_constants/business";
import "./PreviewImage.scss";

const PreviewImage = ({ fileID, style, className }) => {
  const initialState = {
    loading: true,
    url: "",
    err: false,
  };

  const [megaState, setMegaState] = useState(initialState);

  useEffect(() => {
    if (!fileID) {
      setMegaState({ ...initialState, err: true });
      return;
    }

    const body = { fileID };

    request.getImage(
      "/products/getFilePreview",
      body,
      (res) => {
        const blob = res;
        const url = URL.createObjectURL(blob);

        setMegaState({ url, loading: false, err: false });
      },
      () => {
        setMegaState({ ...initialState, err: true, loading: false });
      }
    );
  }, [fileID]);

  const imgDrive = megaState.loading ? (
    <div style={{ ...style }} className={clsx("loading-spinner", className)}>
      <Spin spinning={megaState.loading} />
    </div>
  ) : (
    <img style={{ ...style }} src={megaState.url} className={clsx("img-drive", className)} alt="file view" />
  );

  return megaState.err ? (
    <img style={{ ...style }} src={ERROR_IMAGE_URL} className={clsx("img-drive", className)} alt="file view" />
  ) : (
    <div style={{ ...style }} className={clsx("preview-image", className)}>
      {imgDrive}
    </div>
  );
};

export default PreviewImage;
