const ProductDesc4 = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9062 2.71875L15.6563 4.03125L19.3125 10.6875L25.5 4.5L26.4375 5.4375L19.9688 11.9062L26.1562 23.4375L26.9063 24.75H24.75L26.3438 26.4375L25.4063 27.375L22.875 24.8438H6.9375L4.40625 27.375L3.46875 26.4375L5.0625 24.8438H3.09375L3.84375 23.4375L10.0312 11.8125L3.65625 5.4375L4.59375 4.5L10.6875 10.6875L14.25 4.03125L14.9062 2.71875ZM11.9062 11.9062L15 15L18 12L14.9062 6.28125L11.9062 11.9062ZM15.8437 15.9375L22.875 22.9688H23.9062L18.6562 13.125L15.8437 15.9375ZM21.1875 22.9688L15 16.7813L8.8125 22.9688H21.1875ZM14.1562 15.9375L11.3438 13.125L6 22.9688H7.03125L14.1562 15.9375Z"
        fill="black"
      />
    </svg>
  );
};

export default ProductDesc4;
