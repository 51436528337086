import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import "../CardProduct.scss";
import { FlexBox } from "../../../components";
import CurrentPreviewImage from "../../../components/CurrentPreviewImage/CurrentPreviewImage";
import ArrDownIcon from "../../../icons/ArrDownIcon";
import ArrUpIcon from "../../../icons/ArrUpIcon";

const GroupImage = () => {
  const { productId } = useParams();
  const products = useSelector((state) => state.common.products) ?? [];
  const item = products.find((item) => item._id === productId);
  const [showSide, setShowSide] = useState(false);

  const [allFiles, setAllFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (item) {
      // console.log("New item:", item); // Debug log
      const newAllFiles = item.f?.reduce((acc, color) => acc.concat(color.files), []);
      // console.log("New allFiles:", newAllFiles);
      if (newAllFiles.length > 1) {
        setShowSide(true);
      } else if (newAllFiles.length === 1) {
        setShowSide(false);
      }
      setAllFiles(newAllFiles || []);
      setSelectedImage(newAllFiles[0] || null);
    }
  }, [item]);

  const handleNext = () => {
    if (currentIndex === 5) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allFiles.length);
  };

  const handlePrev = () => {
    if (currentIndex === 0) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + allFiles.length) % allFiles.length);
  };

  const handleImageClick = (file) => {
    setSelectedImage(file);
  };

  return (
    <div className="group_image">
      {showSide && (
        <div className="product-thumbnail-container">
          <div className="product_thumbnails">
            {allFiles.slice(currentIndex, currentIndex + 3).map((file, index) => (
              <div onClick={() => handleImageClick(file)}>
                <CurrentPreviewImage key={file} alt="product" className="product_thumbnail_image" fileID={file} />
              </div>
            ))}
          </div>
          <div className="product_thumbnails_buttons">
            <button onClick={handlePrev} className="product_slider_btn">
              <ArrUpIcon></ArrUpIcon>
            </button>
            <button onClick={handleNext} className="product_slider_btn">
              <ArrDownIcon></ArrDownIcon>
            </button>
          </div>
        </div>
      )}
      <div className="product-main-image-container">
        {selectedImage && (
          <CurrentPreviewImage
            key={selectedImage}
            alt="product"
            className="product-main-image"
            fileID={selectedImage}
          />
        )}
      </div>
    </div>
  );
};

export default GroupImage;
