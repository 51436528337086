const ProductDesc5 = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.4375 14.7188H11.4375C7.40625 14.7188 5.53125 19.4062 4.875 21.375H25.0312C24.75 20.1562 24.0938 17.4375 23.4375 14.7188ZM15.4688 16.6875C16.125 16.6875 16.7812 17.1563 16.7812 17.9063C16.7812 18.5625 16.3125 19.2188 15.5625 19.2188C14.9062 19.2188 14.25 18.75 14.25 18V17.9063C14.25 17.25 14.8125 16.6875 15.4688 16.6875ZM22.9688 13.3125C22.5 11.25 22.0312 9.46875 21.75 8.625L14.0625 8.71875V7.3125L22.4062 7.21875H22.875L23.0625 7.78125C23.0625 7.875 26.5312 22.2188 26.5312 22.2188L26.7188 22.7812H14.9062C10.0312 22.7812 4.21875 22.6875 4.21875 22.6875H3.375L3.65625 21.8437C3.65625 21.8437 5.53125 13.3125 11.7188 13.3125H22.9688Z"
        fill="black"
      />
    </svg>
  );
};

export default ProductDesc5;
