const ProductDesc3 = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.1562 5.90625L26.0625 3L27 3.9375L24.0938 6.84375C27.8438 11.625 27.8438 18.2812 24.0938 23.0625L27 25.9688L26.0625 26.9062L23.1562 24C18.6562 28.5 11.4375 28.5 6.9375 24.0938L6.84375 24L3.9375 26.9062L3 25.9688L5.90625 23.0625C2.15625 18.2812 2.15625 11.625 5.90625 6.84375L3 4.03125L3.9375 3.09375L6.84375 6C11.3438 1.40625 18.5625 1.40625 23.1562 5.90625ZM22.6875 8.15625L15.9375 15L22.7812 21.8437C25.7812 17.8125 25.7812 12.1875 22.6875 8.15625ZM15.0938 15.8438L8.25 22.6875C12 26.4375 18.1875 26.4375 21.9375 22.6875L15.0938 15.8438ZM7.40625 21.6562L14.25 14.9062L7.40625 8.0625C4.40625 12.0938 4.40625 17.625 7.40625 21.6562ZM15.0938 14.0625L21.9375 7.21875C18.1875 3.46875 12 3.46875 8.25 7.21875L15 14.0625H15.0938Z"
        fill="black"
      />
    </svg>
  );
};

export default ProductDesc3;
