import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import clsx from "clsx";
import { Product, Empty } from "../../components";
import { billboard_shop } from "../../images";
import { TEXT_LINK_STEP } from "../../common_constants/business";
import "../Shop/Shop.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import IconFilter from "../../icons/IconFilter";

const red = ["#F44336", "#E91E63", "#FF5722"];
const purple = ["#9C27B0", "#673AB7"];
const blue = ["#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "009688"];
const green = ["#4CAF50", "#8BC34A"];
const yellow = ["#CDDC39", "#FFEB3B", "#FFC107", "FF9800"];
const black = ["#000000", "#795548"];
const grey = ["#607D8B", "#9E9E9E"];
const white = ["#FFFFFF"];
const all = ["*"];

const Availability = () => {
  const location = useLocation();
  const products = useSelector((state) => state.common.products) ?? [];
  const { isDesktopScreen } = useSelector((state) => state.screenSize.deviceType);

  const titleBillboardStandart = "У НАЯВНОСТІ";
  const productsPerPage = 12;

  const [currentPage, setCurrentPage] = useState(0);
  const [titleBillboard, setTitleBillboard] = useState(titleBillboardStandart);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState(all);
  const [selectedPrice, setSelectedPrice] = useState([1234, 1200000]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * productsPerPage;
  const currentPageData = filteredProducts.slice(offset, offset + productsPerPage);

  const text1 = TEXT_LINK_STEP.MAIN;
  const text2 = TEXT_LINK_STEP.SHOP;
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    setTitleBillboard(titleBillboardStandart);

    const filtered = products.filter((product) => {
      const retval = product.status === "1";
      return retval;
    });

    let filtred = [];
    if (selectedColor !== all || selectedPrice[0] !== 1234) {
      for (let i = 0; i < filtered.length; i++) {
        if (selectedColor === all) {
          if (parseInt(filtered[i].p) > selectedPrice[0] && parseInt(filtered[i].p) < selectedPrice[1]) {
            filtred.push(filtered[i]);
          }
        } else {
          for (let j = 0; j < filtered[i].f.length; j++) {
            if (selectedColor.includes(filtered[i].f[j].color)) {
              if (!filtred.includes(filtered[i])) {
                if (parseInt(filtered[i].p) > selectedPrice[0] && parseInt(filtered[i].p) < selectedPrice[1]) {
                  filtred.push(filtered[i]);
                }
              }
            }
          }
        }
      }
      setFilteredProducts(filtred);
    } else {
      setFilteredProducts(filtered);
    }
  }, [location.search, products, selectedColor, selectedPrice]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="shop-page">
      <div className="billboard-container">
        <div className="billboard-label">{titleBillboard}</div>
        <img src={billboard_shop} alt="billboard" className="billboard-image" />
      </div>
      <div className="shop_description">
        <p>
          Отримайте ідеальну куртку для будь-якого випадку! Наші куртки виготовлені з високоякісних матеріалів і
          представлені різноманітними стильними дизайнами. Вибирайте з наших класичних, сучасних і унікальних стилів,
          щоб знайти той, який найкраще пасуватиме до вашого образу. Купуйте зараз і надихайте людей своїм образом вже
          за декілька днів!
        </p>
        <div className="shopFuncs">
          <>
            {titleBillboard !== "МАГАЗИН" ? (
              <div style={{ textTransform: "uppercase", display: "flex", gap: "5px", alignItems: "center" }}>
                <Link to="/" className="shopLink">
                  {text1}
                </Link>
                &gt;&gt;{" "}
                <Link to="/shop" className="shopLink">
                  {text2}
                </Link>{" "}
                &gt;&gt; <div style={{ fontWeight: 600, color: "#000" }}>{titleBillboard}</div>
              </div>
            ) : (
              <div style={{ textTransform: "uppercase", display: "flex", gap: "5px", alignItems: "center" }}>
                <Link to="/" className="shopLink">
                  {text1}
                </Link>
                &gt;&gt;
                <div style={{ fontWeight: 600, color: "#000" }}>{text2}</div>
              </div>
            )}
          </>
          <div className="shopFilters">
            <button className="shopAllFilters" onClick={() => setIsOpenFilters(!isOpenFilters)}>
              <IconFilter></IconFilter>
              Фільтри
            </button>
          </div>
        </div>
        {isOpenFilters && (
          <div className="detailedFilters">
            <div className="detFilCol">
              <h3 className="detFilTitle">Колір:</h3>
              <div className="detColors">
                <button className="detColorsButton" onClick={() => setSelectedColor(black)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "black" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(red)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "red" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(white)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "white" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(green)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "green" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(blue)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "blue" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(yellow)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "yellow" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(purple)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "purple" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(grey)}>
                  <div className="detColorsButtonColor" style={{ backgroundColor: "grey" }}></div>
                </button>
                <button className="detColorsButton" onClick={() => setSelectedColor(all)}>
                  <div className="detColorsButtonColorAll"></div>
                </button>
              </div>
            </div>
            <div className="detFilCol">
              <h3 className="detFilTitle" style={{ marginLeft: "50px" }}>
                Розмір:
              </h3>
              <div className="detSizes">
                <button className="detSizesButton">S</button>
                <button className="detSizesButton">M</button>
                <button className="detSizesButton">L</button>
                <button className="detSizesButton">Xl</button>
                <button className="detSizesButton">XXL</button>
              </div>
            </div>
            <div className="detFilCol">
              <h3 className="detFilTitle">Фільтр цін:</h3>
              <div className="detPrises">
                <button className="detPrisesButton" onClick={() => setSelectedPrice([1234, 1200000])}>
                  Всі
                </button>
                <button className="detPrisesButton" onClick={() => setSelectedPrice([0, 1000])}>
                  0,00 ₴ - 1000,00 ₴
                </button>
                <button className="detPrisesButton" onClick={() => setSelectedPrice([1000, 1500])}>
                  1000,00 ₴ - 1500,00 ₴
                </button>
                <button className="detPrisesButton" onClick={() => setSelectedPrice([1500, 2500])}>
                  1500,00 ₴ - 2500,00 ₴
                </button>
                <button className="detPrisesButton" onClick={() => setSelectedPrice([2500, 1200000])}>
                  2500,00 ₴ +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {filteredProducts?.length > 0 ? (
        <Fragment>
          <div className="product-list">
            {currentPageData.map((item) => (
              <Product key={item._id} item={item} />
            ))}
          </div>

          {filteredProducts?.length > productsPerPage && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(filteredProducts.length / productsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}
        </Fragment>
      ) : (
        <Empty description="Немає товару" w={350} h={250} />
      )}
    </div>
  );
};

export default Availability;
