import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Paying = () => {
  return (
    <div className="InfoPage">
      <div className="InfoPage__content">
        <div style={{ textTransform: "uppercase", display: "flex", gap: "5px", alignItems: "center" }}>
          <Link to="/" className="shopLink">
            Головна
          </Link>
          &gt;&gt; <div style={{ fontWeight: 600, color: "#000" }}>При отриманні</div>
        </div>
        <h3>При отриманні</h3>
        <h4>НАКЛАДЕНИМ ПЛАТЕЖЕМ ЧЕРЕЗ СЛУЖБУ ДОСТАВКИ</h4>
        <h4>МІНІМАЛЬНА ПРЕДОПЛАТА НА НАШ БАНКІВСЬКИЙ РАХУНОК</h4>
        <p>
          Ви оплачуєте мінімальну предоплату. Ви можете сплатити замовлення після його отримання у службі доставки.
          Готівка - такий спосіб оплати доступний: при отриманні товару у відділеннях служб доставки, при кур'єрській
          доставці. Банківська картка - оплата банківською карткою можлива у відділеннях усіх служб доставки.
          <br />
          <br />
          <h4>Оплата при отриманні</h4>
          <br />
          Зверніть увагу! Якщо доставка товару з післяплатою, ви також сплачуєте комісію за переказ коштів згідно з
          тарифами, що діють у відповідній службі доставки. Комісію за переказ коштів утримує фінансова компанія, що
          забезпечує грошові перекази у відповідній службі доставки. Purple Lama не отримує і не повертає комісію за
          переказ коштів.
        </p>
      </div>
    </div>
  );
};

export default Paying;
