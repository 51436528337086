import './ProductDescription.scss';
import { useState } from 'react';
import ProductDesc1 from '../../../icons/ProductDesc1';
import ProductDesc2 from '../../../icons/ProductDesc2';
import ProductDesc3 from '../../../icons/ProductDesc3';
import ProductDesc4 from '../../../icons/ProductDesc4';
import ProductDesc5 from '../../../icons/ProductDesc5';

const ProductDesc = () => {
  return (
    <div className="ProductDescBody">
      <div className="ProductDescLeft">
        <h4>Рекомендації щодо догляду:</h4>
        <div className="PoductDescLeftData">
          <ProductDesc1></ProductDesc1>
          <p>Прати в пральній машині при макс. темп. 30°c</p>
        </div>
        <div className="PoductDescLeftData">
          <ProductDesc2></ProductDesc2>
          <p>Не сушити в сушарці барабанного типу</p>
        </div>
        <div className="PoductDescLeftData">
          <ProductDesc3></ProductDesc3>
          <p>Не чистити хімічно</p>
        </div>
        <div className="PoductDescLeftData">
          <ProductDesc4></ProductDesc4>
          <p>Не відбілювати</p>
        </div>
        <div className="PoductDescLeftData">
          <ProductDesc5></ProductDesc5>
          <p>Прасувати при макс. темп.110°c - без пари</p>
        </div>
      </div>
      <div className="ProductDescRight">
        <h4>Як визначити розмір</h4>
        <img src="https://i.postimg.cc/3NwBj3x5/size-Desc1.png" alt=""></img>
        <img src="https://i.postimg.cc/yYZRMD5p/img-5163.webp" alt=""></img>
      </div>
    </div>
  );
};
const ProductAddInfo = () => {
  return (
    <div className="ProductInfoBody">
      <div className="ProductInfoLeft">
        <div className="PoductInfoLeftData">
          <h3>Замовлення</h3>
          <p>Робіть замовлення на сайті або з Інстаграм сторінки, вкажіть актуальну інформацію для доставки.</p>
        </div>
        <div className="PoductInfoLeftData">
          <h3>Підтвердження замовлення</h3>
          <p>Наш оператор вам зателефонує і повідомить про успішне оформлення замовлення</p>
        </div>
        <div className="PoductInfoLeftData">
          <h3>Відправка товару</h3>
          <p>
            Відправка товару відбувається кожного дня з 12:00 до 18:00. Після відправки товару ви отримаєте сповіщення з номером Товарно-транспортної
            накладної.
          </p>
        </div>
      </div>
      <div className="ProductInfoRight">
        <div className="PoductInfoRightData">
          <h3>Доставка</h3>
          <p>
            ЯКЩО ТОВАРУ НЕМАЄ У НАЯВНОСТІ, термін доставки у будь-який куточок України складають (10-25) робочих днів з моменту внесення часткової,
            або повної оплати.  ЯКЩО ТОВАР Є У НАЯВНОСТІ, термін доставки у будь-який куточок України складають (1-3) календарних дні з моменту
            внесення часткової, або повної оплати. Терміни доставки за кордон обговорюються індивідуально. Інстаграм FASH7UA
          </p>
        </div>
      </div>
    </div>
  );
};

const ProductDescription = () => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveButtonIndex(index);
  };

  return (
    <div className="ProductDescription">
      <div className="Line"></div>
      <div className="ProductDescriptionNav">
        <button className={`ProductDes__button ${activeButtonIndex === 0 ? 'active' : ''}`} onClick={() => handleButtonClick(0)}>
          Опис
        </button>
        <button className={`ProductDes__button ${activeButtonIndex === 1 ? 'active' : ''}`} onClick={() => handleButtonClick(1)}>
          Додаткова інформація
        </button>
      </div>
      <div className="ProductDescription__content">
        {activeButtonIndex === 0 && <ProductDesc></ProductDesc>}
        {activeButtonIndex === 1 && <ProductAddInfo></ProductAddInfo>}
      </div>
    </div>
  );
};

export default ProductDescription;
