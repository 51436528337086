import { useEffect, useState } from "react";
import "./SameProducts.scss";
import { useSelector } from "react-redux";
import { Product } from "../../../components";

const SameProducts = ({ category }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const products = useSelector((state) => state.common.products) ?? [];
  const [filteredProds, setFilteredProds] = useState([]);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const retval = product.c?.[0] === category[0] && product.c?.[1] === category[1];
      return retval;
    });
    setFilteredProds(filtered);
  }, [category, products]);

  return (
    <div className="SameProducts">
      <div className="Line"></div>
      <h3 className="SameProducts__title">Схожі товари</h3>
      <div className="SameProduct__data">
        {filteredProds.length !== 0 &&
          filteredProds.slice(0, 4).map((product) => (
            <div key={product._id} className="SameProducts__item">
              <Product key={product._id} item={product} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default SameProducts;
