import React from "react";
import dayjs from "dayjs";

import { COMPANY_NAME, WORKING_HOURS, WORKING_DAYS } from "../../common_constants/business";
import { icon_instagram, icon_telegram, icon_tictoc } from "../../images";
import { Typography } from "../";
import "./Footer.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const currentYear = dayjs().format("YYYY");

  const [TItle, Text] = [
    ({ children, mt }) => <Typography children={children} mt={mt} sz={14} fw={700} />,
    ({ children, mt }) => <Typography children={children} mt={mt ?? 12} sz={12} fw={400} />,
  ];

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__section">
          <TItle>Про нас</TItle>
          <p className="footer__description">
            FASH7UA - ваш партнер у світі стильного та якісного одягу. Наші вироби поєднують унікальний дизайн з високою
            якістю матеріалів. З FASH7UA ви будете завжди виглядати стильно та впевнено.
          </p>
          <TItle mt={30}>Час роботи</TItle>
          <Text>
            {WORKING_DAYS.START_WEEK} - {WORKING_DAYS.END_WEEK} {WORKING_HOURS.START_DAY} до {WORKING_HOURS.END_DAY}
          </Text>
        </div>
        <div className="footer__section">
          <TItle>Інформація</TItle>

          <Text>
            <Link style={{ textDecoration: "none", color: "black" }} to="/deliver">
              Доставка
            </Link>
          </Text>
          <Text>
            <Link style={{ textDecoration: "none", color: "black" }} to="/qestions">
              Поширені запитання
            </Link>
          </Text>
        </div>
        <div className="footer__section">
          <TItle>Оплата</TItle>

          <Text>
            <Link style={{ textDecoration: "none", color: "black" }} to="/payment">
              При отриманні
            </Link>
          </Text>
        </div>

        <div className="footer__section_messengers">
          <TItle>Зв’яжіться з нами</TItle>

          <button mt={12}>
            <a
              href="https://www.instagram.com/fash7ua?igsh=MW15ZmFmZ3BicXBydg%3D%3D&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon_instagram} alt="btn-login" />
              <TItle>INSTAGRAM</TItle>
            </a>
          </button>
          <button mt={30}>
            <a href="https://t.me/+BTGxxYjDRN02ZTRi" target="_blank" rel="noopener noreferrer">
              <img src={icon_telegram} alt="btn-login" />
              <TItle>TELEGRAM</TItle>
            </a>
          </button>
          <button mt={30}>
            <a href="https://www.tiktok.com/@fash7ua_?_t=8nxXWkjJmcQ&_r=1" target="_blank" rel="noopener noreferrer">
              <img src={icon_tictoc} alt="btn-login" />
              <TItle>TIKTOK</TItle>
            </a>
          </button>
        </div>
      </div>

      <div className="footer__copyright">
        <Text>
          © {currentYear} {COMPANY_NAME} | Всі права захищені
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
